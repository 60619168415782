import { EOrganizationClassification, EOrganizationType } from '~interfaces/Organization';
import { ERoleId } from '~interfaces/Invitation';

export enum EPermissionTarget {
    BENEFIT = 'BENEFIT',
    CDR = 'CDR',
    TOKEN = 'TOKEN',
}

export enum EPermissionAction {
    GET = 'GET',
}

export interface UserRolePermission {
    action: EPermissionAction;
    target: EPermissionTarget;
}

export enum ERoleStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}

export default interface IUserRole {
    organizationClassification: EOrganizationClassification;
    organizationId: string;
    organizationName: string;
    organizationType: EOrganizationType;
    permissions: UserRolePermission[];
    roleId: ERoleId;
    roleName: string;
    status: ERoleStatus;
    userRoleId: string;
}

export type UserRoleConstraintName = string;
export enum ERoleConstraint {
    COMPANY_ADMIN_TO_COMPANY_ADMIN = 'COMPANY_ADMIN_TO_COMPANY_ADMIN',
    COMPANY_ADMIN_TO_COOPERATIVE_ADMIN = 'COMPANY_ADMIN_TO_COOPERATIVE_ADMIN',
    COMPANY_MEMBER_TO_COMPANY_MEMBER = 'COMPANY_MEMBER_TO_COMPANY_MEMBER',
    COMPANY_MEMBER_TO_COOPERATIVE_MEMBER = 'COMPANY_MEMBER_TO_COOPERATIVE_MEMBER',
    COMPANY_MEMBER_TO_FAMILY_ADMIN = 'COMPANY_MEMBER_TO_FAMILY_ADMIN',
    COMPANY_MEMBER_TO_FAMILY_MEMBER = 'COMPANY_MEMBER_TO_FAMILY_MEMBER',
    COOPERATIVE_ADMIN_TO_COMPANY_ADMIN = 'COOPERATIVE_ADMIN_TO_COMPANY_ADMIN',
    COOPERATIVE_ADMIN_TO_COOPERATIVE_ADMIN = 'COOPERATIVE_ADMIN_TO_COOPERATIVE_ADMIN',
    COOPERATIVE_MEMBER_TO_COMPANY_MEMBER = 'COOPERATIVE_MEMBER_TO_COMPANY_MEMBER',
    COOPERATIVE_MEMBER_TO_COOPERATIVE_MEMBER = 'COOPERATIVE_MEMBER_TO_COOPERATIVE_MEMBER',
    COOPERATIVE_MEMBER_TO_FAMILY_ADMIN = 'COOPERATIVE_MEMBER_TO_FAMILY_ADMIN',
    COOPERATIVE_MEMBER_TO_FAMILY_MEMBER = 'COOPERATIVE_MEMBER_TO_FAMILY_MEMBER',
    COOPERATIVE_RESIDENT_TO_COOPERATIVE_RESIDENT = 'COOPERATIVE_RESIDENT_TO_COOPERATIVE_RESIDENT',
    FAMILY_ADMIN_TO_COMPANY_MEMBER = 'FAMILY_ADMIN_TO_COMPANY_MEMBER',
    FAMILY_ADMIN_TO_COOPERATIVE_MEMBER = 'FAMILY_ADMIN_TO_COOPERATIVE_MEMBER',
    FAMILY_ADMIN_TO_FAMILY_ADMIN = 'FAMILY_ADMIN_TO_FAMILY_ADMIN',
    FAMILY_ADMIN_TO_FAMILY_MEMBER = 'FAMILY_ADMIN_TO_FAMILY_MEMBER',
    FAMILY_MEMBER_TO_COMPANY_MEMBER = 'FAMILY_MEMBER_TO_COMPANY_MEMBER',
    FAMILY_MEMBER_TO_COOPERATIVE_MEMBER = 'FAMILY_MEMBER_TO_COOPERATIVE_MEMBER',
    FAMILY_MEMBER_TO_FAMILY_ADMIN = 'FAMILY_MEMBER_TO_FAMILY_ADMIN',
    FAMILY_MEMBER_TO_FAMILY_MEMBER = 'FAMILY_MEMBER_TO_FAMILY_MEMBER',
}

export enum ERoleConstraintSolution {
    CONTACT_SUPPORT = 'CONTACT_SUPPORT',
    CONTACT_SUPPORT_ABOUT_ADMIN_ROLES = 'CONTACT_SUPPORT_ABOUT_ADMIN_ROLES',
    REMOVE_ADMIN_ROLE = 'REMOVE_ADMIN_ROLE',
    REMOVE_FAMILY_MEMBERS = 'REMOVE_FAMILY_MEMBERS',
    REMOVE_MEMBER_ROLE = 'REMOVE_MEMBER_ROLE',
    REMOVE_RESIDENT_ROLE = 'REMOVE_RESIDENT_ROLE',
}

export type UserRoleConstraint = {
    name: UserRoleConstraintName;
    organizationClassification: EOrganizationClassification;
    roleConstraint: ERoleConstraint;
    roleId: ERoleId;
    solutions?: ERoleConstraintSolution[];
};

export type UserRoleConstraintRule = {
    constraints: UserRoleConstraint[];
    organizationClassification: EOrganizationClassification;
    roleId: ERoleId;
};

export enum ERoleConstraintOrigin {
    INVITATION = 'INVITATION',
    REGISTRATION = 'REGISTRATION',
}
