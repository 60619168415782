export enum ETrackingEvent {
    AFTERMARKET_ORDERING = 'aftermarket_ordering',
    CDR_DOWNLOAD = 'cdr_download',
    COUPON_ADD = 'coupon_add',
    DATA_EXPORT_REQUEST = 'data_export_request',
    FILE_DOWNLOAD = 'file_download',
    GDPR_REQUEST = 'gdpr_request',
    INVITATION_ACCEPT = 'invitation_accept',
    INVITATION_OPEN = 'invitation_open',
    INVITATION_REVOKE = 'invitation_revoke',
    INVITATION_SEND = 'invitation_send',
    MEMBERSHIP_ADD = 'membership_add',
    MEMBERSHIP_GET_BONUSES = 'membership_get_bonuses',
    MEMBERSHIP_OWN_REMOVE = 'membership_own_remove',
    MEMBERSHIP_REMOVE = 'membership_remove',
    ORGANIZATION_CREATE = 'organization_create',
    ORGANIZATION_CREATE_CONTACT = 'organization_create_contact',
    ORGANIZATION_CREATE_INFORMATION = 'organization_create_information',
    ORGANIZATION_MANAGEMENT = 'facility_management',
    ORGANIZATION_MIGRATION = 'organization_migration',
    ORGANIZATION_PAYMENT_UPDATE = 'organization_payment_update',
    ORGANIZATION_STATISTICS = 'facility_statistics',
    PAYMENT_UPDATE = 'payment_update',
    REGISTER_USER = 'register_user',
    RFID_ADD = 'rfid_add',
    RFID_ORDER = 'rfid_order',
    RFID_REMOVE = 'rfid_remove',
    RFID_UPDATE = 'rfid_update',
    USER_CREATE = 'user_create',
    VEHICLE_ADD = 'vehicle_add',
}

export enum ETrackingStep {
    ADMIN_EXISTS = 'admin_exists',
    AFTERMARKET_BEGIN = 'aftermarket_begin',
    AFTERMARKET_COMPLETE = 'aftermarket_complete',
    AFTERMARKET_DETAILS = 'aftermarket_details',
    AFTERMARKET_PAYMENT = 'aftermarket_payment',
    AFTERMARKET_PRODUCT = 'aftermarket_product',
    CONTACT = 'contact',
    ERROR = 'error',
    INVITATION_ACCEPT = 'invitation_accept',
    INVITATION_OPEN = 'invitation_open',
    INVITATION_SENT = 'invitation_sent',
    PAYMENT = 'payment',
    PROFILE = 'profile',
    REDIRECT = 'redirect',
    SERVICE = 'service',
}

export enum ETrackingAccountType {
    COOPERATIVE = 'cooperative',
    ORGANIZATION = 'organization',
    USER = 'user',
}

export enum ETrackingAction {
    CHARGER_SHOW_MORE = 'charger_show_more',
    CONTROLLER_CLOSE = 'controller_close',
    CONTROLLER_OPEN = 'controller_open',
    COUPON_ADD = 'coupon_add',
    EXPORT = 'export',
    EXPORT_ERROR = 'export_error',
    FILTER_APPLY = 'filter_apply',
    FILTER_CHOOSE_CONTROLLER = 'filter_choose_controller',
    FILTER_CHOOSE_PROPERTY = 'filter_choose_property',
    FILTER_ONE_PROPERTY = 'filter_one_property',
    FILTER_OPEN = 'filter_open',
    INVOICE_CSV = 'invoice_csv',
    INVOICE_PDF = 'invoice_pdf',
    PROPERTY_CLOSE = 'property_close',
    PROPERTY_OPEN = 'property_open',
}
