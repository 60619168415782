import { EOrganizationClassification } from '~interfaces/Organization';
import { ERoleId } from '~interfaces/Invitation';
import { screenWidthSmall } from '~styles/variables';
import { useUser } from '~contexts/User';
import OrganizationNavigation from '~components/navigation/OrganizationNavigation';
import React, { useMemo } from 'react';
import UserNavigation from '~components/navigation/UserNavigation';
import styled from '@emotion/styled';

type MainNavigationProps = {
    isInMobilePanel?: boolean | undefined;
};

const MainNavigation: React.FC<MainNavigationProps> = (props) => {
    const { isInMobilePanel } = props;
    const { activeRole } = useUser();

    const menu = useMemo(() => {
        switch (activeRole?.roleId) {
            case ERoleId.ADMINISTRATOR: {
                switch (activeRole?.organizationClassification) {
                    case EOrganizationClassification.COOPERATIVE:
                    case EOrganizationClassification.COMPANY:
                    case EOrganizationClassification.PUBLIC: {
                        return <OrganizationNavigation />;
                    }
                    default: {
                        // Typesafe means we cant have a full fall-through, so we need to return here
                        return <UserNavigation />;
                    }
                }
            }
            default: {
                return <UserNavigation />;
            }
        }
    }, [activeRole?.organizationClassification, activeRole?.roleId]);

    return (
        <NavigationContainer className={isInMobilePanel ? 'is-in-mobile-panel' : 'is-outside-of-panel'}>
            {menu}
        </NavigationContainer>
    );
};

export default MainNavigation;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1.5rem 0;

    @media screen and (max-width: ${screenWidthSmall}) {
        padding: 0 0 1rem 0;
    }
`;
