import { EOrganizationClassification } from '~interfaces/Organization';
import { ERoleId } from '~interfaces/Invitation';
import {
    FamilyAdminRoleLink,
    FamilyMemberRoleLink,
    OrganizationAdminRoleLink,
    OrganizationMemberResidentRoleLink,
    RoleLinkProps,
    UserRoleLink,
} from '~components/navigation/NavigationRoleLinks';
import React from 'react';

export default function RoleLink(props: RoleLinkProps): JSX.Element | null {
    const { role: r, changeRole, variant } = props;

    switch (r.organizationClassification) {
        case EOrganizationClassification.FAMILY: {
            switch (r.roleId) {
                case ERoleId.ADMINISTRATOR: {
                    return (
                        <FamilyAdminRoleLink role={r} key={r.userRoleId} changeRole={changeRole} variant={variant} />
                    );
                }
                case ERoleId.MEMBER: {
                    return (
                        <FamilyMemberRoleLink role={r} key={r.userRoleId} changeRole={changeRole} variant={variant} />
                    );
                }
                default: {
                    return null;
                }
            }
        }
        case EOrganizationClassification.COOPERATIVE:
        case EOrganizationClassification.COMPANY:
        case EOrganizationClassification.PUBLIC: {
            switch (r.roleId) {
                case ERoleId.ADMINISTRATOR: {
                    return (
                        <OrganizationAdminRoleLink
                            role={r}
                            key={r.userRoleId}
                            changeRole={changeRole}
                            variant={variant}
                        />
                    );
                }
                case ERoleId.MEMBER: {
                    return (
                        <OrganizationMemberResidentRoleLink
                            role={r}
                            key={r.userRoleId}
                            changeRole={changeRole}
                            variant={variant}
                        />
                    );
                }
                default: {
                    return null;
                }
            }
        }
        default: {
            return <UserRoleLink role={r} key={r.userRoleId} changeRole={changeRole} variant={variant} />;
        }
    }
}
