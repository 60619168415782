{
  "component": {
    "chargeDetailsGraph": {
      "heading": "",
      "noData": {
        "title": "Du har ikke ladet hos Mer i denne perioden",
        "message": "Når du lader får du statistikk og oversikt her"
      }
    }
  },
  "navigation": {
    "home": "Oversikt",
    "profile": "Brukerprofil",
    "profileOrganization": "Organisasjonsprofil",
    "chargingJourney": "Din Laderese",
    "payments": "Faktura",
    "rfids": "RFID brikker",
    "network": "Nettverk",
    "benefits": "Fordeler",
    "facilityManagement": "Administrasjon",
    "facilityStatistics": "Statistikk",
    "refunds": "Omsetningsfordeling",
    "subscriptions": "Abonnementer",
    "group": {
      "customerRelations": "Kundeforhold",
      "chargingFacility": "Ladeanlegg"
    }
  },
  "page": {
    "accessControl": {
      "navigation": {
        "title": "Tilgangsstyring"
      },
      "heading": "Administrator",
      "intro": "Din organisasjonsprofil kan ha én administrator.",
      "bodyStart": "Dersom du ønsker å bytte administrator av organisasjonsprofilen kan du kontakte vår kundeservice. Skriv en e-post til ",
      "bodyEmail": "post.no@mer.eco",
      "bodyEnd": " med hvilken administratorkonto du ønsker deaktivert, samt epostadressen til ny administrator av organisasjonsprofilen. Så snart en ny administrator har blitt lagt til vil vedkommende motta e-post fra Mer med invitasjon til å akseptere administratorrollen for organisasjonsprofilen."
    },
    "account": {
      "navigation": {
        "title": "Min brukerprofil",
        "heading": "Min brukerprofil"
      },
      "dataExports": {
        "heading": "Eksport av organisasjonsdata",
        "introduction": "I henhold til GDPR lovverket kan du be oss om all data tilknyttet din organisasjon. Ved forespørsel vil du motta en epost med link for nedlastning av data fra Mer Hub.",
        "requestExport": "Send forespørsel om organisasjonsdata",
        "requestSent": "Forespørsel om organisasjonsdata er sendt",
        "list": {
          "label": "Organisasjonsdata",
          "heading": "Hentet data",
          "name": "Navn",
          "createdDate": "Oprettet dato",
          "expiryDate": "Utløpsdato",
          "showMore": "Vis flere"
        }
      }
    },
    "benefits": {
      "navigation": {
        "title": "Fordeler",
        "heading": "Fordeler"
      },
      "heading": "Fordeler",
      "showVATInfo": "Alle beløp vises {{vatStatus}}"
    },
    "home": {
      "navigation": {
        "title": "Oversikt",
        "heading": "Oversikt"
      },
      "heading": "Velkommen til Mer, {{name}}",
      "monthlyCharging": {
        "heading": "Månedlig lading"
      },
      "aside": {
        "heading1": "Nytt for deg",
        "heading2": "Dine aktive fordeler"
      },
      "showVATInfo": "Alle beløp vises {{vatStatus}}"
    },
    "chargingJourney": {
      "navigation": {
        "title": "Ladestatistikk",
        "heading": "Ladestatistikk"
      },
      "heading": "Ladestatistikk nettverk",
      "showAllMembers": "Vis for alle",
      "showVATInfo": "Alle beløp vises {{vatStatus}}"
    },
    "network": {
      "navigation": {
        "heading": "Nettverk",
        "title": "Nettverk"
      },
      "heading": "Nettverk",
      "introductionStart": "Sammen er vi Mer!\nSom administrator kan du invitere, finne eller fjerne medlemmer til å knytte seg til organisasjonen i Mer Hub.\n\nDersom du ønsker å bytte administrator kan du lese mer ",
      "introductionLink": "her",
      "introductionEnd": ". ",
      "blocked": "Kundekontoen er blokkert og du kan derfor ikke invitere medlemmer.",
      "invalidPaymentMethod": "Kundekontoen mangler betalingsmetode og du kan derfor ikke legge til medlemmer.",
      "search": "Finn medlem",
      "members": {
        "heading": "Medlemmer",
        "button": {
          "remove": "Fjern kobling"
        },
        "noMembers": "Det er ingen medlemmer i nettverket ditt",
        "noMembersResults": "Det er ingen medlemmer i nettverket ditt som samsvarer '{{search}}'",
        "showMore": "Vis flere"
      },
      "invitations": {
        "heading": "Invitasjoner",
        "button": {
          "remove": "Avbryt invitasjon"
        },
        "noInvitations": "Det er ingen aktive invitasjoner i nettverket ditt",
        "noInvitationsResults": "Det er ingen aktive invitasjoner i nettverket ditt som samsvarer '{{search}}'",
        "confirmModal": {
          "heading": "Inviter medlemmer",
          "body": "Når invitasjonen er akseptert vil organisasjonen belastes for medlemmets fremtidige ladeøkter.",
          "checkboxLabel": "Jeg samtykker til å betale for disse brukerne",
          "button": {
            "cancel": "Avbryt",
            "confirm": "Send invitasjoner"
          }
        },
        "accessControl": {
          "navigation": {
            "title": "Tilgangsstyring"
          },
          "heading": "Tilgangsstyring for administrator",
          "intro": "Du har mulighet til å legge til én administrator av organisasjonsprofilen.",
          "bodyStart": "Dersom du ønsker å bytte administrator av organisasjonsprofilen kan du kontakte vår kundeservice. Skriv en e-post til ",
          "bodyEmail": "post.no@mer.eco",
          "bodyEnd": " med hvilken administratorkonto du ønsker deaktivert, samt epostadressen til ny administrator av organisasjonsprofilen. Så snart en ny administrator har blitt lagt til vil vedkommende motta e-post fra Mer med invitasjon til å akseptere administratorrollen for organisasjonsprofilen."
        }
      },
      "button": {
        "invite": "Invitér medlemmer"
      },
      "moreInfo": {
        "body": "Sammen er vi Mer! Som administrator kan du invitere beboere eller ansatte.\n\nInvitere beboer? Invitasjonen lar beboere knytte seg til ditt borettslag, slik at du enkelt har oversikt over brukerne av i Mer Hub. Beboerne har eget kundeforhold til Mer, og disse brukerene får ikke noen innvirkning på dine kostnader.\n\nInvitere ansatte? Invitasjonen lar ansatte knytte seg til ditt borettslag. Når de har akseptert invitasjonen vil organisasjonen belastes for de neste ladingene."
      }
    },
    "facilityStatistics": {
      "navigation": {
        "title": "Mitt ladeanlegg",
        "heading": "Mitt ladeanlegg",
        "showVATInfo": "Alle beløp vises {{vatStatus}}"
      }
    },
    "facilityManagement": {
      "navigation": {
        "title": "Administrasjon av lokasjon",
        "heading": "Administrasjon av lokasjon"
      },
      "overview": {
        "heading": "Oversikt"
      },
      "prices": {
        "heading": "Priser",
        "noData": "Lokasjon inneholder ingen tilknyttede prislister. Ta kontakt med Mer for videre bistand.",
        "priceListName": {
          "ORGANIZATION": "Organisasjon",
          "PUBLIC": "Offentlig",
          "SMS": "SMS"
        },
        "button": {
          "edit": "Endre priser",
          "submit": "Send endring"
        },
        "link": {
          "publicPricing": "Mer standard priser"
        },
        "field": {
          "location": {
            "label": "Lokasjon(er)"
          },
          "priceList": {
            "label": "Prisoversikt(er)"
          },
          "kwhPrice": {
            "adornment": "kr/kWh"
          },
          "minPrice": {
            "adornment": "kr/min"
          },
          "priceChangedOn": {
            "invalid": "Pris endret må være en fremtidig dato"
          }
        },
        "spotPriceInfo": {
          "heading": "Spotprisavtale for markedsbasert prising",
          "introduction": "Med spotprisavtale følger prisen på lading markedets pris time for time. For å dekke sameiets øvrige kostnader knyttet til ladeanlegget, som for eksempel nettleie og omsetningsfordeling til Mer, kan sameiet sette et valgfritt påslag på spotprisen."
        },
        "fastPriceInfo": {
          "heading": "Fastprisavtale",
          "introduction": "Med fastprisavtale setter sameiet en fast pris på lading. Sameiet bestemmer selv ladeprisen som bør dekke kostnader knyttet til ladeanlegget, som for eksempel strømpris, nettleie og omsetningsfordeling til Mer."
        },
        "table": {
          "header": {
            "product": "Ladeprodukt",
            "kwhPrice": "Pris kr/kWh",
            "spotPrice": "Påslag på spotpris kr/kWh",
            "minPrice": "Pris kr/min",
            "date": "Pris endret"
          }
        }
      },
      "requestSent": {
        "heading": "Prisforespørsel sendt",
        "message": "Ladeprisen vil bli endret innen 1-3 virkedager. Vi gjør oppmerksom på at dere må informere deres beboere om prisendringene.",
        "summaryHeading": "Oppsummering av prisendring",
        "button": {
          "back": "Tilbake"
        }
      },
      "kpi": {
        "total": {
          "heading": "Anleggskapasitet",
          "labelValue": "Totalt installerte",
          "labelTotal": "mulige",
          "info": {
            "heading": "Her kan du se oversikt over anleggskapasiteten.",
            "text1Prefix": "",
            "text1": "Dette forteller deg hvor mange ladere du totalt har installert, og hvor mange ladere det totalt er mulig å installere på den eksisterende infrastrukturen.",
            "text2Prefix": "",
            "text2": ""
          }
        },
        "private": {
          "heading": "Private",
          "installed": {
            "label": "Installerte ladere",
            "labelValue": "Installerte"
          },
          "active": {
            "label": "Aktive ladere",
            "labelValue": "Aktive",
            "labelTotal": "installerte"
          },
          "info": {
            "heading": "Her kan du se oversikt over de private laderne i anlegget.",
            "text1Prefix": "Installerte ladere ",
            "text1": "viser antall private ladere som er installert i anlegget.",
            "text2Prefix": "Aktive ladere ",
            "text2": "viser antall aktive ladere i forhold til antall installerte ladere på anlegget. Dette fortelle deg hvor mange av laderne som er aktive, og hvor mange av ladere som ikke er aktive."
          }
        },
        "public": {
          "heading": "Offentlige",
          "installed": {
            "label": "Installerte ladere",
            "labelValue": "Installerte"
          },
          "active": {
            "label": "Aktive ladere",
            "labelValue": "Aktive",
            "labelTotal": "installerte"
          },
          "info": {
            "heading": "Her kan du se oversikt over de offentlige laderne i anlegget.",
            "text1Prefix": "Installerte ladere ",
            "text1": "viser antall offentlige ladere som er installert i anlegget.",
            "text2Prefix": "Aktive ladere ",
            "text2": "viser antall aktive ladere i forhold til antall installerte ladere på anlegget. Dette fortelle deg hvor mange av laderne som er aktive, og hvor mange av ladere som ikke er aktive."
          }
        }
      }
    },
    "rfids": {
      "navigation": {
        "heading": "RFID brikker",
        "title": "RFID brikker"
      },
      "heading": "RFID brikker",
      "introduction": "Her kan du legge til, aktivere, redigere og få oversikt over alle ladebrikkene knyttet til organisasjonen. Skal du aktivere brikke, kan du legge til brikker fra Mer og våre partnere.",
      "listHeading": "Aktive bestillinger",
      "search": "Finn brikker",
      "blocked": "Kundekontoen er blokkert og du kan ikke bestille eller legge til ladebrikker.",
      "invalidPaymentMethod": "Kundekontoen mangler betalingsmetode og du kan derfor ikke bestille eller legge til laderbrikker.",
      "tags": {
        "label": {
          "serialNumber": "Serienr.",
          "status": "Status",
          "description": "Beskrivelse"
        },
        "status": {
          "ACTIVE": "Aktiv",
          "INACTIVE": "Blokkert",
          "undefined": ""
        },
        "noTags": "Det er ingen aktive RFID brikker i nettverket ditt",
        "noTagResults": "Det er ingen aktive RFID brikker i nettverket ditt som samsvarer '{{search}}'"
      },
      "orders": {
        "label": {
          "status": "Status",
          "member": "Registreres på",
          "description": "Beskrivelse"
        },
        "status": {
          "notProcessed": "Bestilling mottatt",
          "processed": " processed "
        },
        "noOrders": "Det eksisterer ingen ordre på RFID brikker i ditt nettverk",
        "noOrderResults": "Det eksisterer ingen ordre på RFID brikker i ditt nettverk som samsvarer '{{search}}'"
      },
      "button": {
        "order": "Bestill brikker",
        "add": "Aktivér brikke"
      }
    },
    "orderRfids": {
      "heading": "Bestill brikker",
      "formHeading": "Ordredetaljer",
      "priceInfo": "Pris for {{count}} ladebrikke er {{price}}, du vil bli belastet beløpet på din neste faktura{{vatStatus}}.",
      "priceInfo_plural": "Pris for {{count}} ladebrikker er {{price}}, du vil bli belastet beløpet på din neste faktura{{vatStatus}}.",
      "editOrganization": "Endre informasjon",
      "button": {
        "add": "Legg til brikke",
        "remove": "Fjern fra bestilling",
        "cancel": "Avbryt",
        "submit": "Fullfør bestilling"
      }
    },
    "subscriptions": {
      "heading": "Abonnementer",
      "introduction": "Her har du oversikt over abonnementer og løpende kostnader.",
      "listHeading": "Ladere",
      "noSubscriptions": "Du har ingen abonnementer",
      "noChargerSubscriptions": "Du har ingen lader abonnementer",
      "membershipsHeading": "Andre abonnementer",
      "membershipEdit": "Endre",
      "membershipSubscribe": "Abonnér",
      "membershipResubscribe": "Angre oppsigelse",
      "membershipUnsubscribe": "Si opp abonnementet",
      "membershipExpiring": "Utløper {{date}}"
    },
    "refund": {
      "heading": "Omsetningsfordeling",
      "currentPeriod": {
        "organization": "Nåværende periode: {{period}} {{year}}",
        "cooperative": "Inneværende periode: {{period}} {{year}}"
      },
      "previousPeriod": {
        "organization": "Forrige periode: {{period}} {{year}},",
        "cooperative": "Fakturerbar periode: {{period}} {{year}}"
      },
      "period": {
        "first": "1. halvår ",
        "second": "2. halvår ",
        "undefined": ""
      },
      "download": "Last Ned",
      "reportFileName": "omsetningsfordeling_{{period}}_{{year}}.xlsx",
      "revenueCurrentPeriod": "Påløpt beløp i perioden:",
      "revenuePreviousPeriod": "Omsetning i perioden:",
      "listHeaders": {
        "location": "Lokasjon",
        "projectNumber": "Prosjekt nr.",
        "bankAccount": "Bankkontonummer",
        "revenue": "Omsetning",
        "distribution": "Fordeling"
      },
      "totalRevenueCurrentPeriod": "Totalt foreløpig påløpt i perioden: {{total}}",
      "totalRevenuePreviousPeriod": "Total omsetning i perioden: {{total}}",
      "totalRefundCurrentPeriod": "Totalt foreløpig beløp til tilbakebetaling: {{total}}",
      "totalRefundPreviousPeriod": "Beløp til fakturering: {{total}}",
      "lastUpdatedCurrentPeriod": "Oppdateres ukentlig, sist utregnet {{date}}",
      "lastUpdatedPreviousPeriod": "Utregnet {{date}}",
      "invoice": {
        "title": "Faktura sendes per EHF:",
        "heading": "Mer Norway AS",
        "address": "E-post: mer@controlplus.no\nOrganisasjonsnummer: 994 768 832\nAdresse: c/o Kraftsenteret Kjøita 18, 4630 Kristiansand S",
        "projectNumber": "Faktura merkes med prosjektnummer: {{number}}",
        "projectNumberSuffix": " (samme rekkefølge som ovenfor)",
        "description": "Faktura kan sendes fra første virkedag etter at perioden er ferdig. Alle beløp vises {{vatStatus}}, om du er mva-pliktig skal dette tillegges fakturerbart beløp."
      },
      "faqLinkPrefix": "Har du spørsmål til omsetningsfordeling? I vår ",
      "faqLink": "FAQ",
      "faqLinkSuffix": " finner du en oversikt over ofte stilte spørsmål og svar.",
      "cooperative": {
        "currentPeriod": "Inneværende periode: {{period}} {{year}}",
        "previousPeriod": "Fakturerbar periode: {{period}} {{year}}",
        "revenueCurrentPeriod": "Påløpt beløp i perioden:",
        "revenuePreviousPeriod": "Omsetning i perioden:",
        "totalRevenueCurrentPeriod": "Totalt foreløpig påløpt i perioden: {{total}}",
        "totalRevenuePreviousPeriod": "Total omsetning i perioden: {{total}}",
        "totalRefundCurrentPeriod": "Totalt foreløpig beløp til tilbakebetaling: {{total}}",
        "totalRefundPreviousPeriod": "Beløp til tilbakebetaling: {{total}}",
        "lastUpdatedCurrentPeriod": "Oppdateres ukentlig, sist utregnet {{date}}",
        "lastUpdatedPreviousPeriod": "Utregnet {{date}}",
        "vatInvoiceInformation": "Ladetransaksjoner utført i et borettslag som ikke er registrert i Merverdiavgiftsregisteret, tillegges ikke merverdiavgift på faktura.\n\nBorettslaget betaler da selv for strømforbruket og merverdiavgift på det. Mer fakturerer forbruk på laderne, og får innbetalt beløp fra ladende kunder hos borettslaget. Innbetalt beløp med fratrekk for en avtalt omsetningsandel utbetales til borettslaget. ",
        "invoiceInformation": "Utbetalingen vil skje til det kontonummeret som er registrert ved periodens slutt, og utføres halvårlig."
      },
      "organization": {
        "currentPeriod": "Nåværende periode: {{period}} {{year}}",
        "previousPeriod": "Forrige periode: {{period}} {{year}},",
        "revenueCurrentPeriod": "Påløpt beløp i perioden:",
        "revenuePreviousPeriod": "Omsetning i perioden:",
        "totalRevenueCurrentPeriod": "Totalt foreløpig påløpt i perioden: {{total}}",
        "totalRevenuePreviousPeriod": "Total omsetning i perioden: {{total}}",
        "totalRefundCurrentPeriod": "Totalt foreløpig beløp til tilbakebetaling: {{total}}",
        "totalRefundPreviousPeriod": "Beløp til fakturering: {{total}}",
        "lastUpdatedCurrentPeriod": "Oppdateres ukentlig, sist utregnet {{date}}",
        "lastUpdatedPreviousPeriod": "Utregnet {{date}}",
        "vatInvoiceInformation": "",
        "invoiceInformation": ""
      }
    }
  },
  "register": {
    "suggestLogin": "Log in med en annen bruker",
    "loginPrompt": {
      "heading": "Allerede Mer-kunde?",
      "body": "Dersom du allerede er kunde hos Mer kan du bruke samme innlogging for både din private bruker og for å administrere firmakontoen. ",
      "login": "Logg inn nå",
      "loginSuffix": " for å koble de sammen."
    },
    "step": {
      "profile": {
        "heading": "Om organisasjonen",
        "subHeading": "Kontaktinformasjon for organisasjonen",
        "consentHeading": "Samtykke"
      },
      "contact": {
        "heading": "Kontaktperson",
        "subHeading": "Kontaktinformasjon"
      },
      "payment": {
        "heading": "Betaling",
        "paymentType": {
          "card": "Betalingskort",
          "invoice": "Epostfaktura ({{price}} kr per mnd, {{vatStatus}})",
          "ehf": "EHF"
        }
      },
      "profileSave": {
        "heading": ""
      },
      "contactSave": {
        "heading": ""
      },
      "continue": {
        "heading": ""
      },
      "cancel": {
        "heading": ""
      },
      "error": {
        "heading": ""
      },
      "complete": {
        "heading": ""
      }
    },
    "creatingOrganization": "Organisasjonen opprettes…",
    "button": {
      "back": "Tilbake",
      "next": "Gå videre"
    },
    "input": {
      "email": {
        "label": "E-postadresse",
        "cannotMatchOrganization": "Kontakt-e-postadressen kan ikke være den samme som organisasjonens e-postadresse",
        "cannotMatchContact": "Organisasjons-e-postadressen kan ikke være den samme som kontakt-e-postadressen"
      },
      "phone": {
        "label": "Telefonnummer",
        "cannotMatchOrganization": "Kontakttelefonen kan ikke være den samme som organisasjonens telefon",
        "cannotMatchContact": "Organisasjonstelefonen kan ikke være den samme som kontakttelefonen"
      },
      "mobileNumber": {
        "label": "Mobilnummer"
      }
    },
    "error": {
      "heading": "Register organization error",
      "button": {
        "logInOtherUser": "Logg ut og velg en annen bruker",
        "cancel": "Avbryt"
      }
    },
    "acceptTerms": {
      "required": "Du må godta betingelsene for å fortsette",
      "label": {
        "partOne": "Jeg har lest og godtar Mer sine",
        "partTwo": "på vegne av organisasjonen."
      },
      "labelLink": "brukervilkår og betingelser"
    },
    "consentEmail": {
      "label": "Organisasjonen ønsker å motta e-post fra Mer med tilbud, nyheter og annen relevant informasjon."
    }
  },
  "notifications": {
    "missingDetails": {
      "payment": {
        "message": "Brukerkontoen mangler gyldig betalingsmetode. Legg til en gyldig betalingsmetode for å starte lading, bestille/legge til ladebrikke og invitere medlemmer til nettverket.",
        "action": "Legg til betalingsmetode"
      },
      "paymentCardExpiring": {
        "message": "Ditt kort nærmer seg utløpsdato! For å unngå avbrudd i ladetjenestene hos Mer må du oppdatere din betalingsinformasjon før ditt kort når utløpsdato.",
        "action": "Legg til betalingsmetode"
      },
      "paymentCardExpired": {
        "message": "Ditt kort er utløpt! For å gjenopprette ladetjeneste hos Mer må du oppdatere din betalingsinformasjon.",
        "action": "Legg til betalingsmetode"
      }
    }
  },
  "subscription": {
    "Ladefordel": {
      "heading": "Ladefordel",
      "priceExVat": 49,
      "priceIncVat": 61.25,
      "widget": {
        "heading": "Ladefordel",
        "tagline": "",
        "description": "Abonnementet som gir deg\n10% rabatt på all offentlig lading hos Mer!",
        "subscribed": {
          "tagline": "Du har aktivert Ladefordel",
          "description": "Dette gir deg 10% rabatt på alle Mers offentlige ladestasjoner."
        },
        "unsubscribed": {
          "tagline": "Du har stoppet ditt abonnement",
          "description": "Skulle du angre kan du reaktivere abonnementet inntil siste dag denne måneden."
        },
        "button": {
          "subscribe": "Les mer og aktivér",
          "resubscribe": "Angre oppsigelse"
        },
        "link": "Administrer abonnement"
      },
      "modal": {
        "introduction": "Abonnementet lønner seg for bedrifter som lader jevnlig.",
        "features": [
          "10% rabatt i hele vårt landsdekkende og offentlige ladenettverk",
          "Rabatten gjelder alle kjøretøy tilknyttet din Mer-konto",
          "Ingen bindingstid"
        ],
        "description": "Tjenesten koster 49 kr/mnd* og belastes betalingskort/faktura knyttet til bedriftskonto.",
        "subscribed": "Er du sikker? Ved å si opp mister du tilgang til lavere pris på din offentlige lading.",
        "unsubscribed": "Abonnementet avsluttes {{validTo}}. Du kan reaktivere abonnementet dersom du ønsker å fortsette med Ladefordel.",
        "subscribedComplete": {
          "heading": "Du har aktivert Ladefordel",
          "description": "Dette gir deg 10% rabatt på alle Mers offentlige ladestasjoner."
        },
        "unsubscribedComplete": {
          "heading": "Du har stoppet ditt abonnement",
          "description": "Skulle du angre kan du reaktivere abonnementet inntil siste dag denne måneden."
        },
        "field": {
          "agree": {
            "labelPrefix": "Jeg har lest og godkjenner ",
            "labelLink": "brukervilkår og betingelser",
            "labelLinkUrl": "http://mer.eco/terms",
            "labelSuffix": "."
          }
        },
        "alert": {
          "invalidPaymentMethod": {
            "message": "Ingen gyldig betalingsmåte. Legg til kort for å bruke abonnementet.",
            "action": "Legg til betalingsmetode"
          }
        },
        "button": {
          "subscribe": "Aktivér",
          "unsubscribe": "Si opp abonnementet",
          "resubscribe": "Angre oppsigelse",
          "cancel": "Avbryt",
          "close": "Lukk"
        }
      }
    },
    "GoEasy": {
      "heading": "Mer Verdi - GoEasy Remove This All",
      "priceExVat": 0,
      "priceIncVat": 0,
      "widget": {
        "heading": "Mer Verdi (GoEasy)",
        "tagline": "Gjør det rimeligere for bedrifter med behov for jevnlig hurtiglading.",
        "description": "Med Mer Verdi får du 10% avslag på din lading på alle våre offentlige ladestasjoner!",
        "subscribed": {
          "tagline": "Gjør det rimeligere for bedrifter med behov for jevnlig hurtiglading.",
          "description": "Med Mer Verdi får du 10% avslag på din lading på alle våre offentlige ladestasjoner!"
        },
        "unsubscribed": {
          "tagline": "Gjør det rimeligere for bedrifter med behov for jevnlig hurtiglading.",
          "description": "Med Mer Verdi får du 10% avslag på din lading på alle våre offentlige ladestasjoner!"
        },
        "button": {
          "subscribe": "Abonnér",
          "resubscribe": "Reaktivér abonnementet"
        },
        "link": "Administrer abonnement"
      },
      "modal": {
        "introduction": "Mer Verdi (GoEasy) lønner seg for \nbedrifter som lader jevnlig på offentlige ladestasjoner.",
        "features": [
          "Månedsprisen gir 10% rabatt på alle brikker knyttet til din bedriftskonto",
          "Abonnementet fornyes automatisk, men kan stoppes når som helst"
        ],
        "description": "",
        "subscribed": "Dette abonnementet er aktivt og løper inntil du avslutter det.",
        "unsubscribed": "Dette abonnementet er avsluttet. Du kan reaktivere abonnementet dersom du ønsker å fortsette med Mer Verdi. ",
        "subscribedComplete": {
          "heading": "Du har aktivert Ladefordel",
          "description": "Dette gir deg 10% rabatt på alle Mers offentlige ladestasjoner."
        },
        "unsubscribedComplete": {
          "heading": "Du har stoppet ditt abonnement",
          "description": "Skulle du angre kan du reaktivere abonnementet inntil siste dag denne måneden.."
        },
        "field": {
          "agree": {
            "labelPrefix": "Jeg har lest og godtar Mers ",
            "labelLink": "brukervilkår og betingelser",
            "labelLinkUrl": "http://mer.eco/terms",
            "labelSuffix": ". Jeg samtykker til at månedssummen belastes min brukerkonto hos Mer."
          }
        },
        "alert": {
          "invalidPaymentMethod": {
            "message": "Ingen gyldig betalingsmåte. Legg til kort for å bruke abonnementet.",
            "action": "Legg til betalingsmetode"
          }
        },
        "button": {
          "subscribe": "Abonnér",
          "unsubscribe": "Si opp abonnementet",
          "resubscribe": "Reaktivér abonnementet",
          "cancel": "Avbryt",
          "close": "Lukk"
        }
      }
    }
  },
  "roles": {
    "COMPANY": {
      "821200000": "",
      "821200001": "Medlem",
      "821200002": "Administrator",
      "821200003": ""
    },
    "COOPERATIVE": {
      "821200000": "",
      "821200001": "Ansatt",
      "821200002": "Administrator",
      "821200003": "Beboer"
    },
    "FAMILY": {
      "821200000": "",
      "821200001": "Medlem",
      "821200002": "Administrator",
      "821200003": ""
    },
    "MANUFACTURER_VENDOR": {
      "821200000": "",
      "821200001": "Medlem",
      "821200002": "Administrator",
      "821200003": ""
    },
    "PRIVATE": {
      "821200000": "",
      "821200001": "Medlem",
      "821200002": "Administrator",
      "821200003": ""
    },
    "PUBLIC": {
      "821200000": "",
      "821200001": "Medlem",
      "821200002": "Administrator",
      "821200003": ""
    },
    "SERVICE_PARTNER": {
      "821200000": "",
      "821200001": "Medlem",
      "821200002": "Administrator",
      "821200003": ""
    },
    "undefined": {
      "821200000": "",
      "821200001": "Medlem",
      "821200002": "Administrator",
      "821200003": ""
    }
  }
}
