import { EOrganizationClassification } from '~interfaces/Organization';
import { ERoleConstraint, ERoleConstraintSolution, UserRoleConstraintRule } from '~interfaces/UserRoles';
import { ERoleId } from '~interfaces/Invitation';

export const VISIBLE_ORGANIZATION_CLASSIFICATION_ROLES: EOrganizationClassification[] = [
    EOrganizationClassification.FAMILY,
    EOrganizationClassification.COOPERATIVE,
    EOrganizationClassification.COMPANY,
    EOrganizationClassification.PUBLIC,
];

export const userRoleConstraints: UserRoleConstraintRule[] = [
    // Family administrator
    {
        organizationClassification: EOrganizationClassification.FAMILY,
        roleId: ERoleId.ADMINISTRATOR,
        constraints: [
            {
                name: 'FAMILY_ADMIN_TO_FAMILY_MEMBER',
                roleConstraint: ERoleConstraint.FAMILY_ADMIN_TO_FAMILY_MEMBER,
                organizationClassification: EOrganizationClassification.FAMILY,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.CONTACT_SUPPORT],
            },
            {
                name: 'FAMILY_ADMIN_TO_FAMILY_ADMIN',
                roleConstraint: ERoleConstraint.FAMILY_ADMIN_TO_FAMILY_ADMIN,
                organizationClassification: EOrganizationClassification.FAMILY,
                roleId: ERoleId.ADMINISTRATOR,
                solutions: [ERoleConstraintSolution.CONTACT_SUPPORT],
            },
            {
                name: 'FAMILY_ADMIN_TO_COMPANY_MEMBER',
                roleConstraint: ERoleConstraint.FAMILY_ADMIN_TO_COMPANY_MEMBER,
                organizationClassification: EOrganizationClassification.COMPANY,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.CONTACT_SUPPORT],
            },
            {
                name: 'FAMILY_ADMIN_TO_COMPANY_MEMBER',
                roleConstraint: ERoleConstraint.FAMILY_ADMIN_TO_COMPANY_MEMBER,
                organizationClassification: EOrganizationClassification.PUBLIC,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.CONTACT_SUPPORT],
            },
            {
                name: 'FAMILY_ADMIN_TO_COOPERATIVE_MEMBER',
                roleConstraint: ERoleConstraint.FAMILY_ADMIN_TO_COOPERATIVE_MEMBER,
                organizationClassification: EOrganizationClassification.COOPERATIVE,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.CONTACT_SUPPORT],
            },
        ],
    },
    // Family member
    {
        organizationClassification: EOrganizationClassification.FAMILY,
        roleId: ERoleId.MEMBER,
        constraints: [
            {
                name: 'FAMILY_MEMBER_TO_FAMILY_ADMIN',
                roleConstraint: ERoleConstraint.FAMILY_MEMBER_TO_FAMILY_ADMIN,
                organizationClassification: EOrganizationClassification.FAMILY,
                roleId: ERoleId.ADMINISTRATOR,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'FAMILY_MEMBER_TO_FAMILY_MEMBER',
                roleConstraint: ERoleConstraint.FAMILY_MEMBER_TO_FAMILY_MEMBER,
                organizationClassification: EOrganizationClassification.FAMILY,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'FAMILY_MEMBER_TO_COMPANY_MEMBER',
                roleConstraint: ERoleConstraint.FAMILY_MEMBER_TO_COMPANY_MEMBER,
                organizationClassification: EOrganizationClassification.COMPANY,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'FAMILY_MEMBER_TO_COMPANY_MEMBER',
                roleConstraint: ERoleConstraint.FAMILY_MEMBER_TO_COMPANY_MEMBER,
                organizationClassification: EOrganizationClassification.PUBLIC,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'FAMILY_MEMBER_TO_COOPERATIVE_MEMBER',
                roleConstraint: ERoleConstraint.FAMILY_MEMBER_TO_COOPERATIVE_MEMBER,
                organizationClassification: EOrganizationClassification.COOPERATIVE,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
        ],
    },
    // Company administrator
    {
        organizationClassification: EOrganizationClassification.COMPANY,
        roleId: ERoleId.ADMINISTRATOR,
        constraints: [
            // {
            //     name: 'COMPANY_ADMIN_TO_COOPERATIVE_ADMIN',
            //     roleConstraint: ERoleConstraint.COMPANY_ADMIN_TO_COOPERATIVE_ADMIN,
            //     organizationClassification: EOrganizationClassification.COOPERATIVE,
            //     roleId: ERoleId.ADMINISTRATOR,
            //     solutions: [
            //         ERoleConstraintSolution.REMOVE_ADMIN_ROLE,
            //         ERoleConstraintSolution.CONTACT_SUPPORT_ABOUT_ADMIN_ROLES,
            //     ],
            // },
            // {
            //     name: 'COMPANY_ADMIN_TO_COMPANY_ADMIN',
            //     roleConstraint: ERoleConstraint.COMPANY_ADMIN_TO_COMPANY_ADMIN,
            //     organizationClassification: EOrganizationClassification.COMPANY,
            //     roleId: ERoleId.ADMINISTRATOR,
            //     solutions: [
            //         ERoleConstraintSolution.REMOVE_ADMIN_ROLE,
            //         ERoleConstraintSolution.CONTACT_SUPPORT_ABOUT_ADMIN_ROLES,
            //     ],
            // },
            // {
            //     name: 'COMPANY_ADMIN_TO_COMPANY_ADMIN',
            //     roleConstraint: ERoleConstraint.COMPANY_ADMIN_TO_COMPANY_ADMIN,
            //     organizationClassification: EOrganizationClassification.PUBLIC,
            //     roleId: ERoleId.ADMINISTRATOR,
            //     solutions: [
            //         ERoleConstraintSolution.REMOVE_ADMIN_ROLE,
            //         ERoleConstraintSolution.CONTACT_SUPPORT_ABOUT_ADMIN_ROLES,
            //     ],
            // },
        ],
    },
    // Company public administrator
    {
        organizationClassification: EOrganizationClassification.PUBLIC,
        roleId: ERoleId.ADMINISTRATOR,
        constraints: [
            // {
            //     name: 'COMPANY_ADMIN_TO_COOPERATIVE_ADMIN',
            //     roleConstraint: ERoleConstraint.COMPANY_ADMIN_TO_COOPERATIVE_ADMIN,
            //     organizationClassification: EOrganizationClassification.COOPERATIVE,
            //     roleId: ERoleId.ADMINISTRATOR,
            //     solutions: [
            //         ERoleConstraintSolution.REMOVE_ADMIN_ROLE,
            //         ERoleConstraintSolution.CONTACT_SUPPORT_ABOUT_ADMIN_ROLES,
            //     ],
            // },
            // {
            //     name: 'COMPANY_ADMIN_TO_COMPANY_ADMIN',
            //     roleConstraint: ERoleConstraint.COMPANY_ADMIN_TO_COMPANY_ADMIN,
            //     organizationClassification: EOrganizationClassification.COMPANY,
            //     roleId: ERoleId.ADMINISTRATOR,
            //     solutions: [
            //         ERoleConstraintSolution.REMOVE_ADMIN_ROLE,
            //         ERoleConstraintSolution.CONTACT_SUPPORT_ABOUT_ADMIN_ROLES,
            //     ],
            // },
            // {
            //     name: 'COMPANY_ADMIN_TO_COMPANY_ADMIN',
            //     roleConstraint: ERoleConstraint.COMPANY_ADMIN_TO_COMPANY_ADMIN,
            //     organizationClassification: EOrganizationClassification.PUBLIC,
            //     roleId: ERoleId.ADMINISTRATOR,
            //     solutions: [
            //         ERoleConstraintSolution.REMOVE_ADMIN_ROLE,
            //         ERoleConstraintSolution.CONTACT_SUPPORT_ABOUT_ADMIN_ROLES,
            //     ],
            // },
        ],
    },
    // Company member
    {
        organizationClassification: EOrganizationClassification.COMPANY,
        roleId: ERoleId.MEMBER,
        constraints: [
            {
                name: 'COMPANY_MEMBER_TO_FAMILY_ADMIN',
                roleConstraint: ERoleConstraint.COMPANY_MEMBER_TO_FAMILY_ADMIN,
                organizationClassification: EOrganizationClassification.FAMILY,
                roleId: ERoleId.ADMINISTRATOR,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COMPANY_MEMBER_TO_FAMILY_MEMBER',
                roleConstraint: ERoleConstraint.COMPANY_MEMBER_TO_FAMILY_MEMBER,
                organizationClassification: EOrganizationClassification.FAMILY,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COMPANY_MEMBER_TO_COMPANY_MEMBER',
                roleConstraint: ERoleConstraint.COMPANY_MEMBER_TO_COMPANY_MEMBER,
                organizationClassification: EOrganizationClassification.COMPANY,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COMPANY_MEMBER_TO_COMPANY_MEMBER',
                roleConstraint: ERoleConstraint.COMPANY_MEMBER_TO_COMPANY_MEMBER,
                organizationClassification: EOrganizationClassification.PUBLIC,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COMPANY_MEMBER_TO_COOPERATIVE_MEMBER',
                roleConstraint: ERoleConstraint.COMPANY_MEMBER_TO_COOPERATIVE_MEMBER,
                organizationClassification: EOrganizationClassification.COOPERATIVE,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
        ],
    },
    // Company public member
    {
        organizationClassification: EOrganizationClassification.PUBLIC,
        roleId: ERoleId.MEMBER,
        constraints: [
            {
                name: 'COMPANY_MEMBER_TO_FAMILY_ADMIN',
                roleConstraint: ERoleConstraint.COMPANY_MEMBER_TO_FAMILY_ADMIN,
                organizationClassification: EOrganizationClassification.FAMILY,
                roleId: ERoleId.ADMINISTRATOR,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COMPANY_MEMBER_TO_FAMILY_MEMBER',
                roleConstraint: ERoleConstraint.COMPANY_MEMBER_TO_FAMILY_MEMBER,
                organizationClassification: EOrganizationClassification.FAMILY,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COMPANY_MEMBER_TO_COMPANY_MEMBER',
                roleConstraint: ERoleConstraint.COMPANY_MEMBER_TO_COMPANY_MEMBER,
                organizationClassification: EOrganizationClassification.COMPANY,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COMPANY_MEMBER_TO_COMPANY_MEMBER',
                roleConstraint: ERoleConstraint.COMPANY_MEMBER_TO_COMPANY_MEMBER,
                organizationClassification: EOrganizationClassification.PUBLIC,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COMPANY_MEMBER_TO_COOPERATIVE_MEMBER',
                roleConstraint: ERoleConstraint.COOPERATIVE_MEMBER_TO_COOPERATIVE_MEMBER,
                organizationClassification: EOrganizationClassification.COOPERATIVE,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
        ],
    },
    // Cooperative administrator
    {
        organizationClassification: EOrganizationClassification.COOPERATIVE,
        roleId: ERoleId.ADMINISTRATOR,
        constraints: [
            // {
            //     name: 'COOPERATIVE_ADMIN_TO_COMPANY_ADMIN',
            //     roleConstraint: ERoleConstraint.COOPERATIVE_ADMIN_TO_COMPANY_ADMIN,
            //     organizationClassification: EOrganizationClassification.COMPANY,
            //     roleId: ERoleId.ADMINISTRATOR,
            //     solutions: [
            //         ERoleConstraintSolution.REMOVE_ADMIN_ROLE,
            //         ERoleConstraintSolution.CONTACT_SUPPORT_ABOUT_ADMIN_ROLES,
            //     ],
            // },
            // {
            //     name: 'COOPERATIVE_ADMIN_TO_COMPANY_ADMIN',
            //     roleConstraint: ERoleConstraint.COOPERATIVE_ADMIN_TO_COMPANY_ADMIN,
            //     organizationClassification: EOrganizationClassification.PUBLIC,
            //     roleId: ERoleId.ADMINISTRATOR,
            //     solutions: [
            //         ERoleConstraintSolution.REMOVE_ADMIN_ROLE,
            //         ERoleConstraintSolution.CONTACT_SUPPORT_ABOUT_ADMIN_ROLES,
            //     ],
            // },
            // {
            //     name: 'COOPERATIVE_ADMIN_TO_COOPERATIVE_ADMIN',
            //     roleConstraint: ERoleConstraint.COOPERATIVE_ADMIN_TO_COOPERATIVE_ADMIN,
            //     organizationClassification: EOrganizationClassification.COOPERATIVE,
            //     roleId: ERoleId.ADMINISTRATOR,
            //     solutions: [
            //         ERoleConstraintSolution.REMOVE_ADMIN_ROLE,
            //         ERoleConstraintSolution.CONTACT_SUPPORT_ABOUT_ADMIN_ROLES,
            //     ],
            // },
        ],
    },
    // Cooperative member
    {
        organizationClassification: EOrganizationClassification.COOPERATIVE,
        roleId: ERoleId.MEMBER,
        constraints: [
            {
                name: 'COOPERATIVE_MEMBER_TO_COOPERATIVE_MEMBER',
                roleConstraint: ERoleConstraint.COOPERATIVE_MEMBER_TO_COOPERATIVE_MEMBER,
                organizationClassification: EOrganizationClassification.COOPERATIVE,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COOPERATIVE_MEMBER_TO_COMPANY_MEMBER',
                roleConstraint: ERoleConstraint.COOPERATIVE_MEMBER_TO_COMPANY_MEMBER,
                organizationClassification: EOrganizationClassification.COMPANY,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COOPERATIVE_MEMBER_TO_COMPANY_MEMBER',
                roleConstraint: ERoleConstraint.COOPERATIVE_MEMBER_TO_COMPANY_MEMBER,
                organizationClassification: EOrganizationClassification.PUBLIC,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COOPERATIVE_MEMBER_TO_FAMILY_ADMIN',
                roleConstraint: ERoleConstraint.COOPERATIVE_MEMBER_TO_FAMILY_ADMIN,
                organizationClassification: EOrganizationClassification.FAMILY,
                roleId: ERoleId.ADMINISTRATOR,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
            {
                name: 'COOPERATIVE_MEMBER_TO_FAMILY_MEMBER',
                roleConstraint: ERoleConstraint.COOPERATIVE_MEMBER_TO_FAMILY_MEMBER,
                organizationClassification: EOrganizationClassification.FAMILY,
                roleId: ERoleId.MEMBER,
                solutions: [ERoleConstraintSolution.REMOVE_MEMBER_ROLE],
            },
        ],
    },
    // Cooperative resident
    {
        organizationClassification: EOrganizationClassification.COOPERATIVE,
        roleId: ERoleId.RESIDENT,
        constraints: [
            {
                name: 'COOPERATIVE_RESIDENT_TO_COOPERATIVE_RESIDENT',
                roleConstraint: ERoleConstraint.COOPERATIVE_RESIDENT_TO_COOPERATIVE_RESIDENT,
                organizationClassification: EOrganizationClassification.COOPERATIVE,
                roleId: ERoleId.RESIDENT,
                solutions: [ERoleConstraintSolution.REMOVE_RESIDENT_ROLE],
            },
        ],
    },
];
