import { EOrganizationClassification } from '~interfaces/Organization';

export enum EInvitationStatus {
    ACCEPTED = 'ACCEPTED',
    CREATED = 'CREATED',
    DECLINED = 'DECLINED',
    EMAIL_FAILED = 'EMAIL_FAILED',
    EMAIL_SENT = 'EMAIL_SENT',
    EXPIRED = 'EXPIRED',
    REVOKED = 'REVOKED',
}

export enum ERoleId {
    ADMINISTRATOR = '821200002',
    MEMBER = '821200001',
    RESIDENT = '821200003',
    USER = '821200000',
}

export enum EInvitationType {
    ACCOUNT_MIGRATION = 'ACCOUNT_MIGRATION',
    CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
    NORMAL = 'NORMAL',
}

export default interface IInvitation {
    expirationDate: string;
    id: string;
    invitationType: EInvitationType;
    invitedBy: string;
    inviteeEmail: string;
    inviteeName: string;
    organizationClassification: EOrganizationClassification;
    organizationId: string;
    organizationName: string;
    roleId: ERoleId;
    roleName: string;
    status: EInvitationStatus;
}
