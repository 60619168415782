import { EAccountNavigationVariant } from '~components/navigation/AccountNavigation';
import { EOrganizationPaymentType } from '~interfaces/Organization';
import { ERoleId } from '~interfaces/Invitation';
import { EUserPaymentType } from '~interfaces/User';
import { EllipsisText } from '~components/layout';
import {
    colorIllustrationLightBlue,
    colorNeutralsBlack,
    colorNeutralsWhite,
    colorOnWhiteLightGrey,
    colorPrimaryMerBlue,
    font,
    screenWidthMedium,
} from '~styles/variables';
import { formatCardMask, initialsFromOrganizationName, initialsFromUser } from '~utils/formats';
import { useTranslation } from 'react-i18next';
import { useUser } from '~contexts/User';
import CheckIcon from '~assets/icons/checkmark.svg';
import IUserRole from '~interfaces/UserRoles';
import PaymentCardIcon from '~assets/icons/payment_card.svg';
import PaymentEHFIcon from '~assets/icons/payment_ehf.svg';
import PaymentEInvoicingIcon from '~assets/icons/mail.svg';
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import useOrganization from '~hooks/useOrganization';
import useOrganizationPaymentCards from '~hooks/useOrganizationPaymentCards';

export type RoleLinkProps = {
    changeRole: (id?: string) => void;
    role: IUserRole;
    variant?: EAccountNavigationVariant;
};

const USER_PAYMENT_ICONS: Record<EUserPaymentType, JSX.Element | null> = {
    [EUserPaymentType.CARD]: <PaymentCardIcon key={'card-icon'} />,
    [EUserPaymentType.EHF]: <PaymentEHFIcon key={'ehf-icon'} />,
    [EUserPaymentType.E_INVOICE]: <PaymentEInvoicingIcon key={'e_invoice-icon'} />,
    [EUserPaymentType.KLARNA]: null,
    [EUserPaymentType.POSTAL]: null,
    [EUserPaymentType.NONE]: null,
};

const ORGANIZATION_PAYMENT_ICONS: Record<EOrganizationPaymentType, JSX.Element | null> = {
    [EOrganizationPaymentType.CARD]: <PaymentCardIcon key={'card-icon'} />,
    [EOrganizationPaymentType.EHF]: <PaymentEHFIcon key={'ehf-icon'} />,
    [EOrganizationPaymentType.E_INVOICE]: <PaymentEInvoicingIcon key={'e_invoice-icon'} />,
    [EOrganizationPaymentType.POSTAL]: null,
    [EOrganizationPaymentType.NONE]: null,
};

export function UserRoleLink(props: Partial<RoleLinkProps>): JSX.Element | null {
    const { changeRole, ...roleLinkProps } = props;
    const { merUser, activePaymentMethod, activeRole } = useUser();
    const isActive = !activeRole || activeRole.roleId === ERoleId.RESIDENT;

    return merUser ? (
        <RoleLink
            userRoleId={undefined}
            initials={initialsFromUser(merUser)}
            name={`${merUser.firstName} ${merUser.lastName}`}
            payment={activePaymentMethod ?? undefined}
            paymentIcon={USER_PAYMENT_ICONS[merUser.paymentType]}
            changeRole={() => changeRole?.(undefined)}
            active={isActive}
            {...roleLinkProps}
        />
    ) : null;
}

export function FamilyAdminRoleLink(props: RoleLinkProps): JSX.Element | null {
    const { role, changeRole, ...roleLinkProps } = props;
    const { t } = useTranslation(['common']);
    const { merUser, activePaymentMethod } = useUser();
    return merUser ? (
        <RoleLink
            userRoleId={role.userRoleId}
            initials={initialsFromUser(merUser)}
            title={t(`userNavigation.roles.${role?.organizationClassification}.${role?.roleId}`, {
                network: role?.organizationName,
            })}
            name={`${merUser.firstName} ${merUser.lastName}`}
            payment={activePaymentMethod ?? undefined}
            paymentIcon={USER_PAYMENT_ICONS[merUser.paymentType]}
            changeRole={changeRole}
            {...roleLinkProps}
        />
    ) : null;
}

export function FamilyMemberRoleLink(props: RoleLinkProps): JSX.Element | null {
    const { role, changeRole, ...roleLinkProps } = props;
    const { t } = useTranslation(['common']);
    const { merUser } = useUser();
    return merUser ? (
        <RoleLink
            userRoleId={role.userRoleId}
            initials={initialsFromUser(merUser)}
            title={t(`userNavigation.roles.${role?.organizationClassification}.${role?.roleId}`, {
                network: role?.organizationName,
            })}
            name={`${merUser.firstName} ${merUser.lastName}`}
            changeRole={changeRole}
            {...roleLinkProps}
        />
    ) : null;
}

export function OrganizationAdminRoleLink(props: RoleLinkProps): JSX.Element | null {
    const { role, changeRole, ...roleLinkProps } = props;
    const { t } = useTranslation(['common']);
    const { organization } = useOrganization({ organizationId: role.organizationId });
    const { cards } = useOrganizationPaymentCards({
        organizationId: organization?.paymentType === EOrganizationPaymentType.CARD ? role.organizationId : undefined,
    });

    const [activePaymentMethod, paymentIcon]: [string, React.ReactNode | undefined] = useMemo(() => {
        switch (organization?.paymentType) {
            case EOrganizationPaymentType.CARD: {
                return [
                    formatCardMask(cards?.[0]?.CardMask ?? '...'),
                    ORGANIZATION_PAYMENT_ICONS[organization?.paymentType],
                ];
            }
            case EOrganizationPaymentType.E_INVOICE: {
                return [organization?.email, ORGANIZATION_PAYMENT_ICONS[organization?.paymentType]];
            }
            case EOrganizationPaymentType.EHF: {
                return [
                    organization?.ehfNumber ?? '...',
                    organization?.paymentType ? ORGANIZATION_PAYMENT_ICONS[organization?.paymentType] : undefined,
                ];
            }
            default: {
                return ['', undefined];
            }
        }
    }, [cards, organization]);

    return (
        <RoleLink
            userRoleId={role.userRoleId}
            initials={initialsFromOrganizationName(role.organizationName)}
            title={t(`userNavigation.roles.${role?.organizationClassification}.${role?.roleId}`, {
                network: role?.organizationName,
            })}
            name={role.organizationName}
            payment={activePaymentMethod ?? undefined}
            paymentIcon={paymentIcon}
            changeRole={changeRole}
            {...roleLinkProps}
        />
    );
}

export function OrganizationMemberResidentRoleLink(props: RoleLinkProps): JSX.Element | null {
    const { role, changeRole, ...roleLinkProps } = props;
    const { t } = useTranslation(['common']);
    const { merUser } = useUser();
    return merUser ? (
        <RoleLink
            userRoleId={role.userRoleId}
            initials={initialsFromUser(merUser)}
            title={t(`userNavigation.roles.${role?.organizationClassification}.${role?.roleId}`, {
                network: role?.organizationName,
            })}
            name={`${merUser.firstName} ${merUser.lastName}`}
            changeRole={changeRole}
            {...roleLinkProps}
        />
    ) : null;
}

type LinkProps = {
    active?: boolean;
    changeRole: (id?: string) => void;
    initials: string;
    name: string;
    payment?: string;
    paymentIcon?: React.ReactNode;
    title?: string;
    userRoleId?: string;
    variant?: EAccountNavigationVariant;
};

export function RoleLink(props: LinkProps): JSX.Element | null {
    const {
        userRoleId,
        title,
        name,
        payment,
        paymentIcon,
        initials,
        changeRole,
        active,
        variant = EAccountNavigationVariant.NORMAL,
    } = props;
    const { merUser, activeRole } = useUser();
    const isActive = active || userRoleId === activeRole?.userRoleId;

    return merUser ? (
        <RoleLinkContainer onClick={() => changeRole(userRoleId)} className={variant}>
            <div>
                <span className={`avatar ${isActive ? 'active' : ''}`}>{initials}</span>
                <p>
                    <span className={'role'}>{title}</span>
                    <span className={'name'}>{name}</span>
                </p>
                {isActive ? <CheckIcon /> : null}
            </div>
            {variant === EAccountNavigationVariant.NORMAL && payment ? (
                <span className={'payment'}>
                    {paymentIcon}
                    <EllipsisText>{payment}</EllipsisText>
                </span>
            ) : null}
        </RoleLinkContainer>
    ) : null;
}

const RoleLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    border-top: 1px solid ${colorOnWhiteLightGrey};
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;

    > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: stretch;
        gap: 0.75rem;
    }

    p {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        margin: 0;
        flex-grow: 1;
    }

    & svg {
        width: 32px;
        height: auto;
        fill: ${colorPrimaryMerBlue};
        flex-grow: 0;
        flex-shrink: 0;
    }

    .role {
        font-size: ${font.size.xs};
        line-height: ${font.lineHeight.s};
        font-family: 'SharpSans Medium', sans-serif;
    }

    .name {
        display: block;
        font-family: 'SharpSans Semibold', sans-serif;
        line-height: ${font.lineHeight.m};
    }

    .payment {
        padding-left: calc(0.75rem + 48px);
        flex-grow: 1;
        display: flex;
        gap: 0.25rem;
        align-items: center;
        font-size: ${font.size.xs};
        line-height: ${font.lineHeight.m};

        & svg {
            fill: ${colorNeutralsBlack};
            width: 20px;
            height: auto;
        }
    }

    .avatar {
        display: block;
        height: 48px;
        width: 48px;
        line-height: 48px;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        font-size: ${font.size.l};
        background-color: ${colorPrimaryMerBlue};
        color: ${colorNeutralsWhite};
        border-radius: 50%;

        &.active {
            box-shadow: 0 0 0 4px ${colorIllustrationLightBlue};
        }
    }

    &.simple {
        p {
            min-width: 0;
            display: block;
        }

        & .name {
            text-overflow: ellipsis;
            height: 1.25rem;
            overflow: hidden;
            white-space: nowrap;
            min-width: 0;
            display: block;
        }

        & .avatar {
            height: 2.5rem;
            width: 2.5rem;
            line-height: 2.5rem;
            font-size: ${font.size.s};
        }
    }

    @media screen and (max-width: ${screenWidthMedium}) {
        &:first-of-type {
            border-top: 0;
        }
    }
`;
